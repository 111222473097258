import request from '@/utils/request'


// 查询消息已读状态记录列表
export function listRead(query) {
  return request({
    url: '/message/read/list',
    method: 'get',
    params: query
  })
}

// 查询消息已读状态记录分页
export function pageRead(query) {
  return request({
    url: '/message/read/page',
    method: 'get',
    params: query
  })
}

// 查询消息已读状态记录详细
export function getRead(data) {
  return request({
    url: '/message/read/detail',
    method: 'get',
    params: data
  })
}

// 新增消息已读状态记录
export function addRead(data) {
  return request({
    url: '/message/read/add',
    method: 'post',
    data: data
  })
}

// 修改消息已读状态记录
export function updateRead(data) {
  return request({
    url: '/message/read/edit',
    method: 'post',
    data: data
  })
}

// 删除消息已读状态记录
export function delRead(data) {
  return request({
    url: '/message/read/delete',
    method: 'post',
    data: data
  })
}
